
form[name=shop-switch-form] {
  display: flex;
}
form[name=shop-switch-form] > div {
  display: flex;
  margin-bottom: 0;
  flex-grow:1;
}
form[name=shop-switch-form] > div > label {
  margin-right:10px;
  line-height: 36px;
  margin-bottom: 0px;
}
form[name=shop-switch-form] > div > div {
  flex-grow:1;
}
form[name=shop-switch-form] > button {
  margin-left:10px;
}


.localizedText .nav.nav-tabs li {
  margin-bottom: -1px;
}
.localizedText textarea {
  border-top:0px;
}
.localizedText.has-success .nav-tabs {
  border-color: #1ab394;
}
.localizedText.has-error .nav-tabs {
  border-color: #ed5565;
}
.localizedText.has-success .nav-tabs .nav-link.active {
  border-color: #1ab394 #1ab394 #fff;
}
.localizedText.has-error .nav-tabs .nav-link.active {
  border-color: #ed5565 #ed5565 #fff;
}


.fa-check.active[data-v-06ad63e3] {
    color:green;
}
.fa-check.disabled[data-v-06ad63e3] {
    color:red;
}
  

